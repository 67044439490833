<template>
  <div
    class="ib-spinner"
    :class="classes"
  />
</template>

<script>
/* Colors */
const COLORS = ['violet', 'blue', 'yellow', 'red']
export default {
  name: 'IbSpinner',

  props: {
    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'violet'
    }
  },

  computed: {
    classes () {
      return [
        this.colorClass,
        { icon: this.icon },
        { disabled: this.disabled }
      ]
    },

    colorClass () {
      return `ib-spinner-${this.color}`
    }
  }
}
</script>

<style scoped lang="scss">
.ib-spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: spinner-animation 0.8s infinite linear alternate,
  spinner-type 1.6s infinite linear;

  &.ib-spinner-violet {
    border: 3px solid $color-ai;
  }

  &.ib-spinner-blue {
    border: 3px solid $color-primary;
  }

  &.ib-spinner-yellow {
    border: 3px solid $color-yellow;
  }

  &.ib-spinner-red {
    border: 3px solid $color-red-light;
  }
}

@keyframes spinner-animation {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-type {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}
</style>
